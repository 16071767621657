<template>
    <div>
        <st-button
            variant="secondary"
            :callback="setPermissions"
        >
            <i class="fa fa-plus"></i>
            {{ $t('PERMISSIONS.BUTTONS.SET_PERMISSIONS') }}
        </st-button>
        <permissions-modal ref="permissionsModal"></permissions-modal>
    </div>
</template>

<script>
    import PermissionsModal  from '@/modules/permissions/components/PermissionsModal.vue';
    export default {
        name: 'PermissionsListToolbar',
        components: { PermissionsModal},
        methods: {
            setPermissions() {
                this.$refs.permissionsModal.show();
            },
        },
    };
</script>

