import RelationToOneField from '@/shared/fields/relation-to-one-field';
import ApiService from '@/shared/services/api.service';

export class PermissionCategoryField {
    static relationToOne(name, label, rules, filterParams) {
        return new RelationToOneField(
            name,
            label,
            () => {
                return new Promise(async (resolve) => {
                    const params = {
                        fields: 'category',
                        group: 'category',
                        limit: 100,
                    }
                    ApiService.query('/permissions', { params })
                    .then(({ data }) => resolve(data.data));
                });
            },
            (record) => {
                if (!record) {
                    return '';
                }
                return {
                    value: record.category,
                    text: record.category,
                };
            },
            rules,
            filterParams,
        );
    }
}
