import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import { OrganizationField } from '@/modules/administrator/fields/organisations-field';
import { PermissionCategoryField } from '@/modules/permissions/permissions-category_field';

const label = (name) => i18n.t(`PERMISSIONS.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`PERMISSIONS.PLACEHOLDERS.${name.toUpperCase()}`);

const fields = {
    name: new StringField(
        'name',
        label('name'),
        { required: true },
        { placeholder: placeholder('name') }
    ),
    permission_name: new StringField(
        'permission_name',
        label('permission_name'),
        { required: true },
        { placeholder: placeholder('permission_name') }
    ),
    description: new StringField(
        'description',
        label('description'),
        { placeholder: placeholder('description') }
    ),
    category: PermissionCategoryField.relationToOne(
        'category',
        label('category'),
        {},
        { placeholder: placeholder('category') },
    ),
    scope: new StringField(
        'scope',
        label('scope'),
    ),
    organisations: OrganizationField.relationToOne(
        'organisations',
        label('organisations'),
        { required: true },
        {},
        { placeholder: placeholder('organisations') },
    ),
    system_default: new StringField(
        'system_default',
        label('system_default'),
    ),
    default_assigned_to: new StringField(
        'default_assigned_to',
        label('default_assigned_to'),
    ),
    apply_default_to_organisation: new StringField(
        'apply_default_to_organisation',
        label('apply_default_to_organisation'),
    ),
};

export class PermissionsModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
