<template>
    <st-page
        :title="this.$t('PERMISSIONS.HEADER')"
    >
        <template #toolbar>
            <permissions-list-toolbar/>
        </template>
        <permissions-list-filter>
            <permissions-list-table/>
        </permissions-list-filter>
    </st-page>
</template>

<script>
    import PermissionsListToolbar from '../components/PermissionsListToolbar';
    import PermissionsListFilter from '../components/PermissionsListFilter';
    import PermissionsListTable from '../components/PermissionsListTable';

    export default {
        name: 'PermissionsList',
        components: {
            PermissionsListToolbar,
            PermissionsListFilter,
            PermissionsListTable,
        },
    }
</script>
