<template>
    <st-filters-pagination
        stateModule="permissions/list"
        :filters="filters"
        :total="total"
        :moreFilters="false"
        @change="doFilter"
        :header="$t('PERMISSIONS.FILTER.TITLE')"
    >
        <slot></slot>
    </st-filters-pagination>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { FilterSchema } from '@/shared/form/filter-schema';
    import { PermissionsModel } from '@/modules/permissions/permissions-model';

    const { fields } = PermissionsModel;
    const filterSchema = new FilterSchema([
        fields.name,
        fields.permission_name,
        fields.category,
    ]);

    export default {
        name: 'PermissionsListFilter',
        data() {
            return {
                filters: filterSchema.fields,
            };
        },
        computed: {
            ...mapGetters({
               total: 'permissions/list/total',
            }),
        },
        methods: {
            ...mapActions({
                doFetch: 'permissions/list/doFetch',
            }),
            refresh() {
                this.doFilter(this.params);
            },
            doFilter(params) {
                this.params = params;
                this.doFetch(params);
            },
        },

    };
</script>
