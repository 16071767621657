<template>
    <div>
        <st-data-table
            :items="rows"
            :fields="fields"
            :modelPresenter="presenter"
            :loading="isLoading"
            responsive="sm"
            hover
        >
        </st-data-table>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { PermissionsModel } from '@/modules/permissions/permissions-model';

    const { fields } = PermissionsModel;

    export default {
        name: 'PermissionsListTable',
        data() {
            return {
                presenter: PermissionsModel.presenter,
            };
        },
        computed: {
            ...mapGetters({
                rows: 'permissions/list/rows',
                loading: 'shared/loading',
            }),
            isLoading () {
                return this.loading['permissions/getPermissions'] ;
            },
            fields() {
                return [
                    fields.name.extendField({ customField: true, portalTarget: 'enable-truncate'}),
                    fields.permission_name.extendField({ customField: true, portalTarget: 'enable-truncate'}),
                    fields.system_default.extendField({ customField: true, portalTarget: 'enable-truncate'}),
                    fields.default_assigned_to.extendField({ customField: true, portalTarget: 'enable-truncate'}),
                    fields.apply_default_to_organisation.extendField({ customField: true, portalTarget: 'enable-truncate'}),
                    fields.scope,
                    fields.category,
                    fields.description.extendField({ customField: true, portalTarget: 'enable-truncate'}),
                ]
            },
        },
    };
</script>

<style scoped>

</style>
